import { ContactFormSubmissionSchema, ProductSchema } from "~/schemas";
import rawProducts from "~/assets/data/products.json";
import wavyWhiteWall from "~/assets/images/wavy-white-wall.jpg";
import linktCardPhone from "~/assets/images/linkt-card-phone.png";
import FeatureButton from "~/components/FeatureButton";
import badgeLinktSticker from "~/assets/images/badge-linkt-sticker.jpg";
import iphoneConventionProfile from "~/assets/images/iphone-convention-profile.jpg";
import linktCardProfile from "~/assets/images/linkt-card-profile.jpg";
import twoMenMeeting from "~/assets/images/two-men-meeting.jpg";
import { useState } from "react";
import Navbar from "~/components/Navbar";
import ImageGallery from "~/components/ImageGallery";
import Content from "~/components/Content";
import Logo from "~/components/Logo";
import ArrowDown from "~/components/ArrowDown";
import { LargeParagrapgh } from "~/components/Text";
import { TypeAnimation } from "react-type-animation";
import AnimatedUnderlineButton from "~/components/AnimatedUnderlineButton";
import ProductDetail from "~/components/ProductDetail";
import { Separator } from "~/components/ui/separator";
import { scrollToElement } from "~/utils.client";
import { cn } from "~/lib/utils";
import { Theme, useTheme } from "remix-themes";
import { json, Link, useActionData } from "@remix-run/react";
import { ActionFunctionArgs } from "@remix-run/node";
import { withZod } from "@remix-validated-form/with-zod";
import { validationError } from "remix-validated-form";
import ContactForm from "~/components/ContactForm";
import { sendEmail } from "~/send-email.server";
import { z } from "zod";
import { validateTurnstileToken } from "~/cloudflare-turnstile.server";

const products = ProductSchema.array().parse(rawProducts);

const clientValidator = withZod(ContactFormSubmissionSchema);
const serverValidator = withZod(
  ContactFormSubmissionSchema.extend({
    "cf-turnstile-response": z.string(),
  }),
);

export async function action({ request }: ActionFunctionArgs) {
  const result = await serverValidator.validate(await request.formData());
  if (result.error) {
    return validationError(result.error);
  }

  if (process.env.DISABLE_TURNSTILE !== "true") {
    const tokenValidationResult = await validateTurnstileToken(
      result.data["cf-turnstile-response"],
    );
    if (!tokenValidationResult.success) {
      throw json(
        {
          error:
            "Failed to validate the Cloudflare Turnstile token, are you a robot?",
        },
        { status: 400 },
      );
    }
  }

  await sendEmail(result.data);

  return json({ success: true, email: result.data.email });
}

export default function HomeRoute() {
  const [selectedProduct, setSelectedProduct] = useState(products[0]);
  const [theme] = useTheme();
  const actionData = useActionData<typeof action>();
  const successfulSubmit =
    !!actionData && "success" in actionData && actionData.success;
  const submittedEmail = successfulSubmit ? actionData.email : undefined;

  return (
    <div className="">
      <Navbar />
      <section className="relative h-[90vh] bg-slate-500">
        <ImageGallery className="h-full w-full" />
        <div className="absolute inset-0 flex flex-col justify-center items-center text-white">
          <Content className="max-w-[2120px] flex flex-col md:flex-row justify-center items-center gap-16">
            <div className="w-full md:w-3/5">
              <h1 className="text-4xl lg:text-[4.5vw] font-medium leading-[1.125]">
                Smarta kontaktfria lösningar för er verksamhet
              </h1>
              <div className="row-start-2 flex flex-col justify-start xl:justify-end mt-8">
                <p className="text-xl font-extralight">
                  Underlätta för dina kunder och anställda med framtidens
                  kontaktfria Innovationslösningar.
                </p>
              </div>
              <Logo className="hidden md:block w-fit h-[48px] xl:h-[64px] text-white mt-32" />
            </div>
            <div className="flex gap-16">
              <img
                className="w-[280px] md:w-[460px] self-center"
                src={linktCardPhone}
                alt="linkt business card"
              />
              <div className="absolute 2xl:static left-1/2 -translate-x-1/2 bottom-8 flex flex-col justify-center">
                <button
                  className="transition duration-500 ease-in-out hover:translate-y-[15%]"
                  onClick={() => scrollToElement("whatWeOffer")}
                >
                  <ArrowDown className="h-[48px] md:h-[64px] w-fit" />
                </button>
              </div>
            </div>
          </Content>
        </div>
      </section>
      <section className="" id="whatWeOffer">
        <Content
          className={cn(
            "flex flex-col items-center justify-center gap-8 py-24 xl:py-32 bg-cover",
            theme === Theme.DARK ? "bg-background/90" : "bg-background/80",
          )}
          style={{
            backgroundImage: `url(${wavyWhiteWall})`,
            backgroundBlendMode: theme === Theme.DARK ? "darken" : "lighten",
          }}
        >
          <div className="max-w-[1024px] mx-auto">
            <LargeParagrapgh className="text-center">
              <span>Är ni i behov av </span>
              <TypeAnimation
                sequence={[
                  // Same substring at the start will only be typed out once, initially
                  "digitala broschyrer?",
                  2500, // wait 1s before replacing "Mice" with "Hamsters"
                  "digitala namnbrickor?",
                  2500,
                  "digitala visitkort?",
                  2500,
                  "digitala menyer?",
                  2500,
                ]}
                wrapper="span"
                speed={25}
                repeat={Infinity}
              />
            </LargeParagrapgh>
            <p className="text-2xl mt-8">
              Vi erbjuder produkter och tjänster som är anpassade för ett
              flertal olika branscher.
            </p>
          </div>
        </Content>
      </section>
      <section className="pb-24">
        <div
          id="ourProducts"
          className="grid grid-rows-3 md:grid-rows-1 md:grid-cols-3"
        >
          <FeatureButton
            className="h-[320px] xl:h-[26vw]"
            active={selectedProduct.id === products[0].id}
            product={products[0]}
            backgroundImage={linktCardProfile}
            onClick={() => setSelectedProduct(products[0])}
          />
          <FeatureButton
            product={products[1]}
            active={selectedProduct.id === products[1].id}
            backgroundImage={badgeLinktSticker}
            onClick={() => setSelectedProduct(products[1])}
          />
          <FeatureButton
            product={products[2]}
            active={selectedProduct.id === products[2].id}
            backgroundImage={iphoneConventionProfile}
            onClick={() => setSelectedProduct(products[2])}
          />
        </div>
        <Content className="md:py-16 lg:py-24 xl:py-32" variant="narrow">
          <ul className="flex justify-center md:hidden gap-4 py-12">
            <li>
              <AnimatedUnderlineButton
                onClick={() => setSelectedProduct(products[0])}
                active={selectedProduct.id === products[0].id}
              >
                {products[0].title}
              </AnimatedUnderlineButton>
            </li>
            <li>
              <AnimatedUnderlineButton
                onClick={() => setSelectedProduct(products[1])}
                active={selectedProduct.id === products[1].id}
              >
                {products[1].title}
              </AnimatedUnderlineButton>
            </li>
            <li>
              <AnimatedUnderlineButton
                onClick={() => setSelectedProduct(products[2])}
                active={selectedProduct.id === products[2].id}
              >
                {products[2].title}
              </AnimatedUnderlineButton>
            </li>
          </ul>
          <ProductDetail product={selectedProduct} />
        </Content>
        <Content variant="narrow">
          <Separator className="my-8 md:my-0" />
        </Content>
      </section>
      <section
        className="min-h-[600px] py-16"
        style={{
          background: `linear-gradient(
          rgba(0, 0, 0, 0.4), 
          rgba(0, 0, 0, 0.4)
        ),
        url("${twoMenMeeting}") center/cover`,
        }}
        id="contact"
      >
        <Content className="flex justify-center lg:justify-end">
          <ContactForm
            submittedEmail={submittedEmail}
            validator={clientValidator}
          />
        </Content>
      </section>
      <footer className="bg-slate-950 text-muted-foreground py-16 xl:py-48">
        <Content variant="narrow">
          <div className="flex flex-col items-center gap-8">
            <p>info@linkt.se</p>
            <Link to="/integritetspolicy" className="underline">
              Integritetspolicy
            </Link>
          </div>
          <Separator className="bg-muted-foreground/40 mt-16 mb-8" />
          <div className="self-stretch flex flex-col md:flex-row items-center justify-around gap-8">
            <Logo className="h-6 w-fit" />
            <p className="text-center">&copy; 2023 Alla rätter förbehållna.</p>
          </div>
        </Content>
      </footer>
    </div>
  );
}
