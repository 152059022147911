import { ReactNode } from "react";
import { loader } from "~/root";
import { useRouteLoaderData } from "@remix-run/react";

export interface ClassName {
  className?: string;
}

export interface ClassNameAndChildren {
  className?: string;
  children?: ReactNode;
}

export function nonEmptyString(value: unknown): value is string {
  return typeof value === "string" && value !== "";
}

export function useClientEnvs() {
  const data = useRouteLoaderData<typeof loader>("root");
  if (!data) {
    throw new Error(
      "useRouteLoaderData failed: No data found in the route loader",
    );
  }
  return data.ENV;
}
