import { LargeParagrapgh, UppercaseH2 } from "~/components/Text";
import { ValidatedForm, Validator } from "remix-validated-form";
import AnimatedUnderlineInput from "~/components/AnimatedUnderlineInput";
import AnimatedUnderlineTextarea from "~/components/AnimatedUnderlineTextarea";
import { Checkbox } from "~/components/ui/checkbox";
import { Link } from "@remix-run/react";
import InputError from "~/components/InputError";
import { Button } from "~/components/ui/button";
import { Icon } from "@iconify/react";
import { cn } from "~/lib/utils";
import { useClientEnvs } from "~/utils";
import { ClientOnly } from "~/components/ClientOnly";
import Turnstile from "react-turnstile";

interface ContactFormProps {
  submittedEmail?: string;
  validator: Validator<{ [p: string]: any }>;
}

export default function ContactForm({
  submittedEmail,
  validator,
}: ContactFormProps) {
  const clientEnvs = useClientEnvs();

  return (
    <div className="relative max-w-[600px] bg-background p-8 md:p-12 lg:p-16 xl:mr-[20%]">
      <div
        className={cn("flex flex-col w-full", submittedEmail && "invisible")}
      >
        <UppercaseH2 className="mb-4">Kontakta oss</UppercaseH2>
        <LargeParagrapgh className="mb-8">
          Vill du veta mer? Kontakta oss!
        </LargeParagrapgh>
        <ValidatedForm
          className="flex flex-col gap-6"
          method="post"
          validator={validator}
        >
          <AnimatedUnderlineInput name="name" placeholder="Namn" />
          <div className="flex flex-col md:grid grid-cols-2 gap-x-8 gap-6">
            <AnimatedUnderlineInput
              name="organization"
              placeholder="Organisation"
            />
            <AnimatedUnderlineInput name="email" placeholder="E-postadress" />
          </div>
          <AnimatedUnderlineTextarea name="comment" placeholder="Kommentar" />
          <div>
            <div className="flex items-center space-x-2">
              <Checkbox name="agreeToPolicy" id="policy" />
              <label
                htmlFor="policy"
                className="text-sm text-muted-foreground leading-none peer-disabled:cursor-not-allowed peer-disabled:opacity-70"
              >
                Jag godkänner att personuppgifterna behandlas i enlighet med vår{" "}
                <Link to="/integritetspolicy" className="underline">
                  Integritetspolicy
                </Link>
                .
              </label>
            </div>
            <InputError className="mt-1" name="agreeToPolicy" />
          </div>
          {clientEnvs.DISABLE_TURNSTILE !== "true" &&
            clientEnvs.TURNSTILE_SITE_KEY && (
              <ClientOnly>
                {() => (
                  <Turnstile
                    sitekey={clientEnvs.TURNSTILE_SITE_KEY!}
                    fixedSize
                  />
                )}
              </ClientOnly>
            )}
          <Button className="w-1/2 mx-auto gap-2">
            <Icon
              className="h-7 w-fit"
              icon="material-symbols-light:send-outline"
            />
            <span>Skicka</span>
          </Button>
        </ValidatedForm>
      </div>
      {submittedEmail && (
        <div className="absolute inset-0 flex flex-col p-8 md:p-12 lg:p-16">
          <UppercaseH2 className="mb-4">Kontakta oss</UppercaseH2>
          <LargeParagrapgh className="mb-8">
            Tack för ditt intresse!
          </LargeParagrapgh>
          <p>
            Vi återkommer så fort vi kan till den angivna e-postadressen{" "}
            <span className="font-semibold">{submittedEmail}</span> med ett
            svar.
          </p>
        </div>
      )}
    </div>
  );
}
